import React, { ReactElement, useCallback } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  RaRecord,
  ReferenceArrayInput,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import classNames from 'classnames';
import { ResourceName } from 'dd-cms-client/app/resources';
import { CategoryStatus } from 'dd-cms-client/category/CategoryList';
import { Component, Props } from './types';
import './CategorySelector.scss';

const CategorySelector: Component = ({
  isDisabled,
  isMultiple = false,
  hasAllCategories = false,
  source,
  ...restInputProps
}: Props): ReactElement => {
  const InputComponents = isMultiple ? {
    Autocomplete: AutocompleteArrayInput,
    Reference: ReferenceArrayInput,
  } : {
    Autocomplete: AutocompleteInput,
    Reference: ReferenceInput,
  };

  const disabledClassName = classNames(
    {
      'Field--Disabled': isDisabled,
    },
  );

  const getInputText = useCallback(
    (record: RaRecord) => {
      return `${record.fields.name} (${record.fields.tenant})`;
    },
    [],
  );

  const getOptionContent = useCallback(
    (record: RaRecord) => {
      const className =  record.fields.active === CategoryStatus.INACTIVE ? 'CategorySelector-Category--Inactive' : '';
      return `<span class="${className}">${getInputText(record)}</span>`;
    },
    [getInputText],
  );

  const OptionRenderer = () => {
    const record = useRecordContext();

    return (
      <div dangerouslySetInnerHTML={{ __html: getOptionContent(record) }} />
    );
  };

  const matchSuggestion = useCallback(
    (filter: string, record: RaRecord) => (
      record.fields.name.toLowerCase().includes(filter.toLowerCase())
    ),
    [],
  );

  return (
    <InputComponents.Reference
      sort={{ field: 'name', order: 'DESC' }}
      perPage={100}
      reference={hasAllCategories ? ResourceName.ALL_CATEGORIES : ResourceName.CATEGORY}
      source={source}
    >
      {/* @ts-ignore */}
      <InputComponents.Autocomplete
        {...restInputProps}
        className={disabledClassName}
        filterToQuery={
          (searchText: string) => (
            {
              'fields.name': searchText,
            }
          )
        }
        inputText={getInputText}
        matchSuggestion={matchSuggestion}
        optionText={<OptionRenderer />}
        optionValue="id"
      />
    </InputComponents.Reference>
  );
};

export {
  CategorySelector,
};
