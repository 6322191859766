import React, { ReactElement } from 'react';
import { RadioButtonGroupInput, usePermissions } from 'react-admin';
import classNames from 'classnames';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { Component, Props } from './types';

const SelectTenant: Component = ({
  className,
  ...restInputProps
}: Props): ReactElement => {
  const rootClassNames = classNames(
    'SelectTenant',
    className,
  );
  const { permissions } = usePermissions();

  const userTenantsChoices = permissions?.userTenants.map(
    (tenant: Tenant) => ({
      label: tenant,
      value: tenant,
    }),
  ) || [];

  return (
    <RadioButtonGroupInput
      {...restInputProps}
      className={rootClassNames}
      defaultValue={userTenantsChoices?.[0]?.value || null}
      choices={userTenantsChoices}
      optionText="label"
      optionValue="value"
    />
  );
};

export {
  SelectTenant,
};
