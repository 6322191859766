import React, { ReactElement, useCallback, useEffect } from 'react';
import { AutocompleteInput, RaRecord, ReferenceInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DEFAULT_TENANT } from 'dd-cms-client/auth/utils/tenant';
import { usePrevious } from 'dd-cms-client/common/hooks/usePrevious';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component, Props } from './types';
import './SelectDeal.scss';

const SelectDeal: Component = ({
  className,
  hasFilters = true,
  source,
  ...restInputProps
}: Props): ReactElement => {
  //tenant input is needed when using in create comment form
  const { getValues, resetField } = useFormContext();
  const tenantInput = getValues('fields')?.tenant;
  const prevTenantInput = usePrevious(tenantInput);

  const OptionRenderer = () => {
    const record = useRecordContext();

    return (
      <div className="SelectDeal-Option">
        {record.fields.mainImage && (
          <img
            className="SelectDeal-OptionImage"
            src={`${getConfig('url.cdn')}/${record.fields.mainImage}`}
            alt={record.fields.title}
          />
        )}

        {record.fields.title} - {record.fields.navisionId}
      </div>
    );
  };

  const inputText =  useCallback(
    (choice: RaRecord) => choice.fields.title,
    [],
  );

  const matchSuggestion = useCallback(
    (filter: string, choice: RaRecord) => (
      choice.fields.title.toLowerCase().includes(filter.toLowerCase())
    ),
    [],
  );

  const getEndDate = useCallback(
    () => {
      const dateTime = new Date();
      const year = dateTime.getFullYear();
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
      const day = ('0' + dateTime.getDate()).slice(-2);
      const hour = ('0' + dateTime.getHours()).slice(-2);

      //full hours added to avoid unnecessary rerenders, api requests for ReferenceInput
      return `${year}-${month}-${day} ${hour}:59:59`;
    },
    [],
  );

  useEffect(
    () => {
      //when radio tenant is changed, deal select input has to be reset
      if (tenantInput && prevTenantInput) {
        resetField(source);
      }
    },
    [resetField, tenantInput], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ReferenceInput
      filter={
        hasFilters
          ? {
            'fields.endDate': getEndDate(),
            'fields.status': 'published',
            'fields.tenant': tenantInput || DEFAULT_TENANT,
          }
          : {}
      }
      sort={{ field: 'endDate', order: 'DESC' }}
      perPage={100}
      reference={ResourceName.ALL_DEALS}
      source={source}
    >
      <AutocompleteInput
        {...restInputProps}
        className={className}
        filterToQuery={
          (searchText: string) => (
            {
              'fields.title': searchText,
            }
          )
        }
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        optionValue="id"
      />
    </ReferenceInput>
  );
};

export {
  SelectDeal,
};
